@import url('https://fonts.googleapis.com/css2?family=Cabin+Sketch&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,600;1,400;1,600&display=swap')

// available fonts
// font-family: 'Source Sans Pro', sans-serif
// font-family: 'Cabin Sketch', cursive

html, body 
    height: calc(100% - 32px)
    width: calc(100% - 32px)
    margin: 8px
    padding: 8px

    display: flex
    flex-direction: column

    h1, h2, h3, h4, h5, p, a, button
        font-family: 'Source Sans Pro', sans-serif

    a, button
        min-width: 120px
        min-height: 40px
        width: auto
        height: auto
        padding: 0px
        height: 0px
        outline: none
        border: none

    .header
        width: 100%

        h1, p 
            width: 100%
            margin: 0px
            padding: 0px

            text-align: center

        h1 
            font-family: 'Cabin Sketch', cursive
            font-size: 64px

        p
            font-size: 24px
            font-weight: bold

        .contact
            display: flex
            justify-content: center

            a
                display: flex
                text-decoration: none
                font-weight: bold
                margin: 0px 12px 0px 4px

            span
                margin: auto 0px auto 4px
                color: rgb(0,0,0)

    button
        border: none
        outline: none
        text-decoration: uppercase

    .container
        height: auto

    .content
        position: relative
        display: flex
        flex-direction: column
        max-width: 1024px
        height: auto
        padding: 48px 0px 16px 0px
        margin: auto
        
        .bio
            margin: 0px auto 32px auto
            
            .text
                font-size: 24px
                text-align: justify
                margin: 0px

            img
                max-height: 320px
                float: right
                margin: 8px 8px 8px 20px
                border-radius: 4px

        .media-container
            display: flex
            flex-wrap: wrap

            max-width: 1024px
            padding: 0px 0px 64px 0px

            .filters
                display: flex
                justify-content: center
                width: 100%

                button
                    color: black
                    border-bottom: solid 1.5px rgb(255,255,255)
                    text-transform: uppercase
                    font-weight: bold
                    cursor: pointer
                    background-color: rgb(255, 255, 255)
                    transform: border 120ms ease-in-out

                    &.active, &:hover.active
                        border-bottom: solid 1.5px rgb(0,0,0)

                    &:hover
                        border-bottom: solid 1.5px rgb(200, 200, 200)

            .video
                flex-basis: 100%
                min-height: 560px
                max-height: 640px
                width: 100%
                height: 100%
                margin: 32px auto

            img
                margin: 8px
                border-radius: 4px
                max-height: 240px

    .footer
        display: flex
        justify-content: center

        p
            width: auto
            color: rgb(200, 200, 200)
            
.icon
    &:before
        content: ""
        display: inline-block
        width: 32px
        height: 32px
        margin: auto 0px
        background-repeat: no-repeat
        background-size: cover

    &.instagram
        &:before
            background-image: url('/static/media/instagram.svg')
    &.cashapp
        &:before
            background-image: url('/static/media/cashapp.svg')
    &.venmo
        &:before
            background-image: url('/static/media/venmo.svg')
    &.gmail
        &:before
            background-image: url('/static/media/gmail.svg')

@media only screen and (max-width: 450px)

    html, body
        .header 
            h1
                font-size: 64px
            p
                font-size: 16px
            .contact
                flex-wrap: wrap

                a
                    font-size: 14px

        .content 
            .bio 
                .text
                    text-align: left
                    font-size: 18px
                    padding: 4px

                .image-container 
                    display: flex
                    justify-content: center

                    img
                        float: none
                        max-height: 420px
                        margin: 12px auto

            .media-container 
                justify-content: center

                .video
                    min-height: 320px

                img
                    max-width: 80%
                    max-height: 320px